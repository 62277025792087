











import { mensajes_chatAllData } from "@/shared/dtos/chat/mensajes_chatAllData";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class message_operator extends Vue {
  @Prop() mensaje!: mensajes_chatAllData;
}
